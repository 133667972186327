<template>
  <div>
    <datatable
      ref="table"
      :ajax="true"
      :ajaxPagination="true"
      :columns="comments.columns"
      :dropdown="false"
      :fillable="true"
      :filters="comments.filters"
      :query="comments.query"
      :on-click="click"
      :url="comments.url"
    >
      <template #header>
        <div class="pt-4">
          <search-form
            placeholder="Search admin name, email, comment type, comment body etc."
            class="mb-5"
            v-model="comments.query"
            @submit="reloadTable"
          />
          <div class="flex items-start flex-wrap xl:flex-no-wrap">
            <div class="flex flex-wrap items-center w-full">
              <span class="processor-filter-title">Filters:</span>
              <template v-for="(filter, index) in comments.filters">
                <span
                  v-html="filter.title"
                  :key="index"
                  class="processor-filter-item"
                  :class="{ active: currentFilter === filter }"
                  @click.prevent="toggleFilter(filter)"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </datatable>

    <modal
      className="w-full md:w-6/10 lg:w-4/10 xl:w-4/10"
      ref="viewCommentModal"
    >
      <template v-if="comments.selected">
        <h5 class="mb-2">{{ commentAdminName }}'s Comment</h5>
        <p class="mb-5 text-gray-600">
          Comment On: {{ getCommentType(comments.selected) }}
        </p>
        <blockquote class="mb-10">
          <p>
            <span class="font-bold text-gray-400 text-xl">"</span>
            {{ commentBody }}
            <span class="font-bold text-gray-400 text-xl">"</span>
          </p>
        </blockquote>
        <template v-if="isSelectedCommentOnResource">
          <template v-if="isSelectedCommentOnStatement">
            <div class="mb-4">
              <div
                class="grid grid-cols-1 md:grid-cols-2 border border-blue-200 rounded mb-10"
              >
                <div class="col-span-1 p-4">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Statement Range
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    from {{ commentResource.start_date | dateFormat('M Y') }} to
                    {{ commentResource.end_date | dateFormat('M Y') }}
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Opening Balance
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.opening_balance | currency }}
                  </div>
                </div>
                <div class="col-span-1 p-4 border-t border-blue-200">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Amount Spent
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.agg_balance | currency }}
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Deferred Charge
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.deferred_plan_amount | currency }}
                  </div>
                </div>
                <div class="col-span-1 p-4 border-t border-blue-200">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Total
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.total_outstanding | currency }}
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Status
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.condition }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="isSelectedCommentOnRepayment">
            <div class="font-medium mb-2 text-gray-600">
              Repayment Information
            </div>
            <div class="mb-4">
              <div
                class="grid grid-cols-1 md:grid-cols-2 border border-blue-200 rounded mb-10"
              >
                <div class="col-span-1 p-4">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Repayment Due Date
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.due_date | dateFormat('dd M Y') }}
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Loan Id
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.loan_id }}
                  </div>
                </div>
                <div class="col-span-1 p-4 border-t border-blue-200">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Repayment Amount
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.amount | currency }}
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Defaulted
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.defaulted ? 'Yes' : 'No' }}
                  </div>
                </div>
                <div class="col-span-1 p-4 border-t border-blue-200">
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Date Paid
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    <template v-if="commentResource.paid_at">
                      {{
                        commentResource.paid_at || 'N/A' | dateFormat('dd M Y')
                      }}
                    </template>
                    <template v-else>
                      N/A
                    </template>
                  </div>
                </div>
                <div
                  class="col-span-1 p-4 border-t md:border-l border-blue-200"
                >
                  <div class="opacity-75 text-sm font-medium mb-1">
                    Status
                  </div>
                  <div
                    class="text-gray-500 text-sm font-light flex flex-row items-center"
                  >
                    <ion-icon
                      name="cash-outline"
                      class="text-lg mr-2"
                    ></ion-icon>
                    {{ commentResource.status }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>
      <div class="text-center mt-20">
        <button
          type="button"
          class="btn btn-blue"
          @click.prevent="$refs.viewCommentModal.close"
        >
          Close
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      currentFilter: null,
      comments: this.$options.resource([], {
        columns: [
          {
            th: 'Admin',
            name: 'admin',
            render: comment => {
              return `${comment.admin?.name} ${comment.admin?.last_name}`;
            }
          },
          {
            th: 'Comment On',
            name: 'commentable_type',
            render: comment => {
              return this.getCommentType(comment);
            }
          },
          {
            th: 'Date',
            name: 'created_at',
            render: comment => {
              return this.$moment(comment?.created_at).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              );
            }
          },
          {
            th: 'Comment',
            name: 'body',
            render: comment => {
              return this.$options.filters.truncate(comment.body);
            }
          }
        ],
        filters: [
          { name: 'declined', title: 'Declined' },
          { name: 'statement', title: 'Statement' },
          { name: 'repayment', title: 'Repayment' },
          { name: 'uncertain', title: 'Uncertain' }
        ],
        selected: null,
        url: `${this.$baseurl}/admin/personal/users/${this.staff.id}/comments`
      })
    };
  },
  computed: {
    commentAdmin() {
      return this.comments.selected?.admin;
    },
    commentAdminName() {
      return this.commentAdmin?.name;
    },
    commentBody() {
      return this.comments.selected?.body;
    },
    commentResource() {
      return this.comments.selected?.commentable;
    },
    isSelectedCommentOnResource() {
      return (
        this.comments.selected?.commentable_type !== 'App\\AdminUserAction'
      );
    },
    isSelectedCommentOnRepayment() {
      return this.comments.selected?.commentable_type === 'App\\Repayment';
    },
    isSelectedCommentOnStatement() {
      return (
        this.comments.selected?.commentable_type ===
        'App\\PersonalAccountStatement'
      );
    },
    userId() {
      return this.staff.id;
    }
  },
  beforeMount() {
    // this.getComments();
  },
  methods: {
    click(comment) {
      this.comments.selected = comment;
      this.$nextTick(() => {
        this.$refs.viewCommentModal.open();
      });
    },
    getCommentType(comment) {
      const commentable = comment.commentable;
      switch (comment.commentable_type) {
        case 'App\\AdminUserAction':
          switch (commentable.action) {
            case 'activate':
              return 'Account Activated';
            case 'decline':
              return 'Credit Declined';
            case 'flag:not_suitable':
              return 'Flagged: Not Suitable';
            case 'flag:self_employed':
              return 'Flagged: Self Employed';
            case 'flag:uncertain':
              return 'Flagged: Uncertain';
            case 'refute_bank_account':
              return 'Bank Account Refutal';
            case 'verify':
              return 'Information Verified';
            case 'verify_bank_account':
              return 'Bank Account Verified';
            default:
              return commentable.action;
          }
        default:
          return comment.commentable_type?.replace(/^[\w\\]+\\(\w+)$/, '$1');
      }
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    async getComments() {
      this.comments.setLoading();
      await this.sendRequest('admin.users.comments.all', this.staff.id, {
        success: response => {
          this.comments.setValue(response.data.datatable.data);
        },
        error: error => {
          this.comments.error = error;
        }
      });
      this.comments.setLoading(false);
    }
  }
};
</script>
